import React from "react"
import TextField from "../RoleDetail/TextField/TextField"
import "./ContactCompany.css"

import { createService } from "../../../../services/service"

const SERVICES = ["Business Development", "Sales", "Recruitment", "Others"]

type CONTACT_DATA_TYPE = {
  label: string
  value: string
}

const CONTACT_SERVICES_FORM: CONTACT_DATA_TYPE[] = [
  {
    label: "YOUR NAME",
    value: "name",
  },
  {
    label: "EMAIL ADDRESS",
    value: "email",
  },
  {
    label: "YOUR MESSAGE",
    value: "description",
  },
]

const ContactCompany = () => {
  const [service, setService] = React.useState<string[]>([])

  const [inputData, setInputData] = React.useState<{
    name: string
    email: string
    phone: string
    link: string
    description: string
  }>({
    name: "",
    email: "",
    description: "",
    phone: "",
    link: "",
  })

  const [sendingData, setSendingData] = React.useState<{
    message: string
    messageType: string
    status: string
  }>({
    message: "",
    messageType: "success",
    status: "none",
  })

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setSendingData({ ...sendingData, message: "", status: "loading" })
    e.preventDefault()
    if (
      inputData.name !== "" &&
      inputData.email !== "" &&
      inputData.description !== "" &&
      service.length > 0
    ) {
      createService({
        email: inputData.email,
        name: inputData.name,
        message: inputData.description,
        service: service,
      })
        .then((res) => {
          if (res.status === 200) {
            setSendingData({
              message: "Thank you for your contact. We will reply you soon",
              status: "done",
              messageType: "success",
            })

            setInputData({
              name: "",
              email: "",
              description: "",
              phone: "",
              link: "",
            })
            setService([])
          } else {
            setSendingData({
              message: "Fail to update the data to server, something wrong in the server",
              status: "none",
              messageType: "error",
            })
          }
        })
        .catch(() => {
          setSendingData({
            message: "Please double check your network, cannot send the request to server",
            status: "none",
            messageType: "error",
          })
        })
    } else {
      setSendingData({
        message: "Please check fill all neccessery data",
        status: "none",
        messageType: "error",
      })
    }
  }

  return (
    <div className='contact-company-v2'>
      <h4>We love to hear from you, get in touch!</h4>
      <h5>hello@deltacognition.com</h5>
      <div className='mt-12 flex flex-col gap-3'>
        <span className='text-[#6B7181] font-open_sans text-[12px] leading-[18px]'>
          CHOOSE THE CATEGORY
        </span>
        <ul className='flex gap-3 flex-wrap w-full'>
          {SERVICES.map((item) => {
            return (
              <li
                key={item}
                className={[
                  "rounded-2xl px-3 py-[6px] w-fit text-[14px] leading-[20px] cursor-pointer border font-open_sans font-semibold",
                  service.includes(item)
                    ? "bg-[#5C3BDE] text-white"
                    : "text-black border-[#E6E8EC] hover:bg-[#6B7181] hover:bg-opacity-20 duration-500",
                ].join(" ")}
                onClick={() => {
                  const isExist = service.includes(item)
                  const data = isExist ? service.filter((e) => e !== item) : [...service, item]
                  setService(data)
                }}
              >
                {item}
              </li>
            )
          })}
        </ul>
      </div>
      <form className='mt-6 flex flex-col' id='service-form'>
        {CONTACT_SERVICES_FORM.map((item) => {
          return (
            <div key={item.value} className='flex flex-col w-full gap-3'>
              {item !== CONTACT_SERVICES_FORM[CONTACT_SERVICES_FORM.length - 1] && (
                <div>
                  <TextField
                    {...{
                      id: item.value,
                      hint: item.label,
                      text: "",
                      type: "text",
                      bgColor: "bg-[#F4F5F6]",
                      value: inputData,
                      setValue: setInputData,
                      error: "",
                      validateFunc: (e) => {
                        console.log(e)
                      },
                    }}
                  />
                  {sendingData.messageType === "error" &&
                    inputData[(item.value as "name") || "email" || "description"] == "" && (
                      <p className={["text-red-500 mt-[-25px] mb-8"].join(" ")}>
                        Please fill out this field
                      </p>
                    )}
                </div>
              )}
              {item === CONTACT_SERVICES_FORM[CONTACT_SERVICES_FORM.length - 1] && (
                <>
                  <label
                    htmlFor={item.value}
                    className='font-open_sans text-[#6B7181] font-normal text-[12px]'
                  >
                    {item.label}
                  </label>
                  <textarea
                    name={item.value}
                    value={inputData.description}
                    onChange={(e) =>
                      setInputData({ ...inputData, [e.target.name]: e.target.value })
                    }
                    className='w-full bg-[#F4F5F6] rounded-lg h-[118px] p-3 outline-[#5C3BDE] resize-none'
                  ></textarea>
                  <div className='flex justify-between items-center'>
                    <span className='text-[14px] text-[#6B7181] font-open_sans'>
                      Maximum 512 characters
                    </span>
                    <div className='w-fit text-[14px] rounded-[14px] border px-3 py-1'>
                      {inputData?.description.length || 0}/512
                    </div>
                  </div>
                </>
              )}
            </div>
          )
        })}
        <button
          className='bg-[#5C3BDE] flex justify-center items-center rounded-3xl h-12 text-white mt-6'
          type='submit'
          form='service-form'
          onClick={(e) => handleSubmit(e)}
        >
          {sendingData.status === "loading" && (
            <svg
              className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <circle
                className='opacity-25'
                cx='12'
                cy='12'
                r='10'
                stroke='currentColor'
                strokeWidth='4'
              ></circle>
              <path
                className='opacity-75'
                fill='currentColor'
                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
              ></path>
            </svg>
          )}
          Send now
        </button>
        <p
          className={[
            "ml-2",
            sendingData.messageType === "error" ? "text-red-500" : "text-green-500",
          ].join(" ")}
        >
          {sendingData.message}
        </p>
      </form>
    </div>
  )
}

export default ContactCompany
